import defaultState from "./defaultState";
import { StageBackgroundReduxDispatchActionType, StageBackgroundReduxStateType } from "./types";

const reducer: (state: StageBackgroundReduxStateType & any, action: StageBackgroundReduxDispatchActionType) => StageBackgroundReduxStateType
    = (state: StageBackgroundReduxStateType & any = defaultState, action: StageBackgroundReduxDispatchActionType) => {
        switch (action.type) {
            default:
                return state;
        }
    };

export default reducer;
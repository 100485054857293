import { connect } from 'react-redux';
import Component from './component';
import { StageBackgroundReduxDispatchActionType, StageBackgroundReduxDispatchResultType, StageBackgroundReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';

const mapDispatchToProps: (dispatch: Dispatch<StageBackgroundReduxDispatchActionType>) => StageBackgroundReduxDispatchResultType
  = (dispatch: Dispatch<StageBackgroundReduxDispatchActionType>) => {
    return {

    }
  };

const mapStateToProps: (state: any) => StageBackgroundReduxStateType = (state: any) => {
  return state[4];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
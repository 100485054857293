import { connect } from 'react-redux';
import Component from './component';
import { BooksContainerReduxDispatchActionType, BooksContainerReduxDispatchResultType, BooksContainerReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';
import { BookType } from '../../types';

const mapDispatchToProps: (dispatch: Dispatch<BooksContainerReduxDispatchActionType>) => BooksContainerReduxDispatchResultType
  = (dispatch: Dispatch<BooksContainerReduxDispatchActionType>) => {
    return {
      handleIncrementClick: () => dispatch({ type: 'BooksContainer.INCREMENT', }),
      handleDecrementClick: () => dispatch({ type: 'BooksContainer.DECREMENT', }),
      setBooks: (books: Array<BookType|null>) => dispatch({ type: 'BooksContainer.SET_BOOKS', value: books, }),
      setTotal: (total: number) => dispatch({ type: 'BooksContainer.SET_TOTAL', value: total, }),
    }
  };

const mapStateToProps: (state: any) => BooksContainerReduxStateType = (state: any = {}) => {
  return state[2];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
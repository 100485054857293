import { connect } from 'react-redux';
import Component from './component';
import { BookSelectionScreenReduxDispatchActionType, BookSelectionScreenReduxDispatchResultType, BookSelectionScreenReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';

const mapDispatchToProps: (dispatch: Dispatch<BookSelectionScreenReduxDispatchActionType>) => BookSelectionScreenReduxDispatchResultType
  = (dispatch: Dispatch<BookSelectionScreenReduxDispatchActionType>) => {
    return {
    }
  };

const mapStateToProps: (state: any) => BookSelectionScreenReduxStateType = (state: any = {}) => {
  return state[5];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
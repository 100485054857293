import { BookType } from "./types";
import { ClientApplication, createClient } from 'cosmic-cubs-story-maker-backend';
import feathers from "@feathersjs/client";
import rest from '@feathersjs/rest-client';
import cookie from 'cookie';
import { Paginated } from '@feathersjs/feathers/lib/declarations';

const getcccToken = () => cookie.parse(document.cookie).cccToken;

const cookieDomain = 'lab.scribedevelopers.com';
const cookieMaxAge = 365*24*60*60;

const cookieStorage = (cookieName: string) => ({
    getItem(name: string) {
        return cookie.parse(document.cookie)[cookieName];
    },
    setItem(name: string, newValue: string) {
        document.cookie = cookie.serialize(cookieName, newValue, {
            domain: cookieDomain,
            maxAge: cookieMaxAge,
            sameSite: 'strict',
          });
    },
    removeItem(name: string) {
        document.cookie = cookie.serialize(cookieName, '', {
            domain: cookieDomain,
            expires: new Date(),
          });
    },
});


let _fetch = (input: RequestInfo | URL, init?: RequestInit | undefined): Promise<Response> => {
    return fetch(input, {
        ...init,
        credentials: 'include',
    });
}

const connection = rest('https://api.cosmic-cubs-story-maker.lab.scribedevelopers.com').fetch(_fetch)
const client = createClient(connection);

const getBooks: (page: number) => Promise<Paginated<BookType>> = async (page) => {
    await client.configure((app: ClientApplication) => {
        app.authentication.options.storage = cookieStorage('cccToken');
    });
    // await client.configure(feathers.authentication({
    //     storage: cookieStorage('cccToken'),
    // }));
    await client.authentication.setAccessToken(getcccToken());

    let books = await client.services['stories'].find({
        query: {
            $limit: 5,
            $skip: page * 5,
        }
    });

    return books;
};

const deleteBook: (bookId: string) => void = async (bookId) => {

    await client.authentication.setAccessToken(getcccToken());

    await client.services['stories'].remove(bookId);

};

const addBook: (book: Omit<BookType, '_id'>) => Promise<BookType> = async (book) => {

    await client.authentication.setAccessToken(getcccToken());

    return await client.services['stories'].create(book);

}

const updateBook: (book: BookType) => Promise<BookType> = async (book) => {

    if(!('_id' in book && typeof book._id === 'string')) throw new Error('Invalid book id.');

    await client.authentication.setAccessToken(getcccToken());

    return await client.services['stories'].patch(book._id, book);

}



export default {
    getBooks,
    deleteBook,
    addBook,
    updateBook,
};
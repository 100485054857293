import defaultState from './defaultState';
import { BooksContainerReduxDispatchActionType, BooksContainerReduxStateType } from './types';

const reducer: (state: BooksContainerReduxStateType & any, action: BooksContainerReduxDispatchActionType) => BooksContainerReduxStateType
    = (state: BooksContainerReduxStateType & any = defaultState, action: BooksContainerReduxDispatchActionType) => {
        switch (action.type) {
            case 'BooksContainer.INCREMENT':
                return {
                    ...state,
                    page: state.page + 1,
                }
            case 'BooksContainer.DECREMENT':
                return {
                    ...state,
                    page: state.page - 1,
                }
            case 'BooksContainer.SET_BOOKS':
                if (
                    'value' in action
                    && typeof action.value === 'object'
                    && 'length' in action.value
                )
                    return {
                        ...state,
                        books: action.value,
                    }
                else return state;
            case 'BooksContainer.SET_TOTAL':
                if (
                    'value' in action
                    && typeof action.value === 'number'
                )
                    return {
                        ...state,
                        total: action.value,
                    }
                else return state;
            case 'SET_SELECTED_BOOK':
                return {
                    ...state,
                    selectedBook: action.value,
                }
            default:
                return state;
        }
    };

export default reducer;
import defaultState from "./defaultState";
import { TextInputReduxDispatchActionType, TextInputReduxStateType } from "./types";

const reducer: (state: TextInputReduxStateType & any, action: TextInputReduxDispatchActionType) => TextInputReduxStateType
    = (state: TextInputReduxStateType & any = defaultState, action: TextInputReduxDispatchActionType) => {
        switch (action.type) {
            case 'SET_TEXT_INPUT_PARAMS':
                return action.value;
            case 'UPDATE_TEXT_INPUT_PARAMS':
                return {
                    ...state,
                    ...action.value,
                };
            case 'SET_SELECTED_BOOK':
                return defaultState;
            default:
                return state;
        }
    };

export default reducer;
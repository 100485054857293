import defaultState from './defaultState';
import { BookSelectionScreenReduxDispatchActionType, BookSelectionScreenReduxStateType } from './types';

const reducer: (state: BookSelectionScreenReduxStateType & any, action: BookSelectionScreenReduxDispatchActionType) => BookSelectionScreenReduxStateType
    = (state: BookSelectionScreenReduxStateType & any = defaultState, action: BookSelectionScreenReduxDispatchActionType) => {
        switch (action.type) {
            case 'SET_SELECTED_BOOK':
                return {
                    ...state,
                    selectedBook: action.value,
                };
            default:
                return state;
        }
    };

export default reducer;
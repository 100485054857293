import defaultState from "./defaultState";
import { BookPreviewReduxDispatchActionType, BookPreviewReduxStateType } from "./types";

const reducer: (state: BookPreviewReduxStateType & any, action: BookPreviewReduxDispatchActionType) => BookPreviewReduxStateType
    = (state: BookPreviewReduxStateType & any = defaultState, action: BookPreviewReduxDispatchActionType) => {
        switch (action.type) {
            case "SET_SELECTED_BOOK":
                return {
                    ...state,
                    book: action.value,
                };
            case "UPDATE_TEXT_INPUT_PARAMS":
                return {
                    ...state,
                    textInputValue: action.value.value,
                }
            default:
                return state;
        }
    };

export default reducer;
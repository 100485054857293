import React, { useEffect, useState } from 'react';
import { TextInputPropsType, TextInputReduxDispatchResultType, TextInputReduxStateType } from './types';

const TextInput = (props: (TextInputPropsType & TextInputReduxDispatchResultType & TextInputReduxStateType)) => {
    let [value, setValue] = useState(props.value);
    let style: React.CSSProperties = {
        outline: 'none',
        resize: 'none',
        ...(props.style !== undefined && props.style),
        ...(!props.visible && { display: 'none' }),
        width: props.width,
        height: props.height,
        position: 'absolute',
        top:
            props.y
            - ((
                props.anchor !== undefined
                    ? typeof props.anchor === 'number'
                        ? props.anchor
                        : props.anchor.y
                    : 0.5
            ) * props.height),
        left:
            props.x
            - ((
                props.anchor !== undefined
                    ? typeof props.anchor === 'number'
                        ? props.anchor
                        : props.anchor.x
                    : 0.5
            ) * props.width),
        boxSizing: 'border-box',
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return (
        props.elementType === 'textarea'
            ? <textarea
                style={style}
                onChange={e => {
                    props.updateTextInputParams({
                        value: e.target.value,
                    });
                }}
                value={value}
            />
            : <input
                style={style}
                {
                    ...(
                        'type' in props && {
                            type: props.type,
                        }
                    )
                }
                onChange={e => {
                    props.updateTextInputParams({
                        value: e.target.value,
                    });
                }}
                value={value}
            />

    );
};

export default TextInput;
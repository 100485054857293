import { connect } from 'react-redux';
import Component from './component';
import { BookReduxDispatchActionType, BookReduxDispatchResultType, BookReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';
import { BookType } from '../../types';

const mapDispatchToProps: (dispatch: Dispatch<BookReduxDispatchActionType>) => BookReduxDispatchResultType
  = (dispatch: Dispatch<BookReduxDispatchActionType>) => {
    return {
      createNewBook: () => dispatch({ type: "CREATE_NEW_STORY" }),
      setSelectedBook: (book: BookType|null) => dispatch({ type: "SET_SELECTED_BOOK", value: book }),
    };
  };

const mapStateToProps: (state: any) => BookReduxStateType = (state: any) => {
  return state[1];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './configure-store';
import './index.css';
import { Container as App } from './components/App/container';
import reportWebVitals from './reportWebVitals';
import * as PIXI from 'pixi.js';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

PIXI.Assets.load("/fonts/SquadaOne-Regular.ttf").then(() => 
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App store={store}/>
      </Provider>
    </React.StrictMode>
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from 'react';
import { Assets, Texture } from 'pixi.js';
import { Sprite } from '@pixi/react';
import { StageBackgroundPropsType, StageBackgroundReduxDispatchResultType, StageBackgroundReduxStateType } from './types';

const StageBackground = (props: StageBackgroundPropsType & StageBackgroundReduxDispatchResultType & StageBackgroundReduxStateType) => {
    const [x] = useState(0);
    const [y] = useState(0);
    const [texture, setTexture] = useState<Texture | undefined>();

    useEffect(() => {
        (async () => {
            setTexture(await Assets.load("/images/trim1.png"));
        })();
    }, [setTexture]);
    useEffect(() => {
        texture?.update();
    }, [texture]);

    return (
        !!texture ?
            <Sprite
                x={x}
                y={y}
                width={760}
                height={480}
                texture={texture}
            >
            </Sprite>
            : <></>
    );
};

export default StageBackground;
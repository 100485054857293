import { connect } from 'react-redux';
import Component from './component';
import { TextInputReduxDispatchActionType, TextInputReduxDispatchResultType, TextInputReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';

const mapDispatchToProps: (dispatch: Dispatch<TextInputReduxDispatchActionType>) => TextInputReduxDispatchResultType
  = (dispatch: Dispatch<TextInputReduxDispatchActionType>) => {
    return {
      updateTextInputParams: (params: Partial<TextInputReduxStateType>) => { dispatch({ type: 'UPDATE_TEXT_INPUT_PARAMS', value: params}); },
    };
  };

const mapStateToProps: (state: any) => TextInputReduxStateType = (state: any) => {
  return state[6];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import appReducer from './components/App/reducer';
import bookReducer from './components/Book/reducer';
import booksContainerReducer from './components/BooksContainer/reducer';
import bookPreviewReducer from './components/BookPreview/reducer';
import stageBackgroundReducer from './components/StageBackground/reducer';
import bookSelectionScreenReducer from './components/BookSelectionScreen/reducer';
import textInputReducer from './components/TextInput/reducer';

const reducer = combineReducers([
    appReducer,
    bookReducer,
    booksContainerReducer,
    bookPreviewReducer,
    stageBackgroundReducer,
    bookSelectionScreenReducer,
    textInputReducer,
]);

export const store = configureStore({
    reducer,
});
import { useCallback, useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import { Texture, ObservablePoint } from 'pixi.js';
import { Container, TilingSprite } from '@pixi/react';
import { Container as Book } from '../Book/container';
import { BooksContainerReduxDispatchResultType, BooksContainerReduxStateType, BooksContainerPropsType } from './types';
import { BookType } from '../../types';
import apiController from '../../apiController';

const BooksContainer = (props: (BooksContainerPropsType & BooksContainerReduxDispatchResultType & BooksContainerReduxStateType)) => {
    const [texture, setTexture] = useState<Texture>();
    const getBooks = useCallback(() => {
        (async () => {
            let response = await apiController.getBooks(props.page);
            props.setBooks(response.data);
            props.setTotal(response.total);
        })()
    }, [props.setBooks, props.page]);

    useEffect(() => {
        getBooks();
    }, [props.setBooks, props.page]);
    useEffect(() => {
        (async () => {
            setTexture(PIXI.Texture.WHITE);
        })();
    }, [setTexture]);

    return (
        <Container {...props}>
            {
                texture
                && 'page' in props
                && props.page !== undefined
                && props.page < (props.total - 5) / 5
                    ? <TilingSprite
                        interactive
                        cursor='pointer'
                        texture={texture}
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        width={20}
                        height={20}
                        x={310}
                        y={120}
                        pointertap={() => props.handleIncrementClick()}
                    />
                    : <></>
            }
            {
                texture
                && 'page' in props
                && props.page !== undefined
                && props.page > 0
                    ? <TilingSprite
                        interactive
                        cursor='pointer'
                        texture={texture}
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        width={20}
                        height={20}
                        x={-10}
                        y={120}
                        pointertap={() => props.handleDecrementClick()}
                    />
                    : <></>
            }
            {
                [
                    ...props.books,
                    {
                        _id: 'new',
                        author: '',
                        title: ''
                    },
                ].map((book: (BookType|null), i) => {
                    return <Book
                        book={book}
                        key={i}
                        selected={props.selectedBook?._id === book?._id}
                        width={80}
                        height={120}
                        x={20 + (i % 3) * 100}
                        y={(Math.floor(i / 3)) * 140}
                        store={props.store}
                    />;
                })
            }
        </Container>
    );
};

export default BooksContainer;
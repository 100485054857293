import { useState, useEffect, forwardRef, useRef, Ref } from 'react';
import * as PIXI from 'pixi.js';
import { Texture, ObservablePoint } from 'pixi.js';
import { Container, Text, TilingSprite } from '@pixi/react';
import { BookPreviewButtonsPropsType, BookPreviewPropsType, BookPreviewReduxDispatchResultType, BookPreviewReduxStateType } from './types';
import { BookType } from '../../types';

const BookPreview = (props: BookPreviewPropsType & BookPreviewReduxDispatchResultType & BookPreviewReduxStateType) => {
    const bookPreviewButtonsRef = useRef<PIXI.Container>(null);
    const [bookPreviewButtonsBounds, setBookPreviewButtonsBounds] = useState<PIXI.Rectangle | null | undefined>();
    const [texture, setTexture] = useState<Texture>();
    const [editingTitle, setEditingTitle] = useState(false);
    const [dimensions] = useState({
        height: 460,
        width: 370,
    });
    const ref = useRef<PIXI.Container>(null);
    const [globalPosition, setGlobalPosition] = useState<PIXI.Point | null>(null);
    const [book, setBook] = useState<BookType|null>(props.book);

    useEffect(() => {
        (async () => {
            setTexture(PIXI.Texture.WHITE);
        })();
    }, [setTexture]);
    useEffect(() => {
        if (!bookPreviewButtonsRef.current) return;
        bookPreviewButtonsRef.current.calculateBounds();
        setTimeout(() => {
            setBookPreviewButtonsBounds(bookPreviewButtonsRef?.current?.getBounds());
        }, 1);
    }, [bookPreviewButtonsRef]);
    useEffect(() => {
        if(!ref.current) return;
        setGlobalPosition(ref.current.getGlobalPosition());
    }, [ref]);
    useEffect(() => {
        setBook(props.book);
        setEditingTitle(false);
    }, [props.book]);
    // useEffect(() => {
    //     if(!editingTitle || !book) return;
    //     setBook({
    //         ...book,
    //         title: props.textInputValue,
    //     });
    // }, [props.textInputValue])

    return (
        <Container
            x={props.x}
            y={props.y}
            ref={ref}
        >
            {
                texture
                    ? <TilingSprite
                        texture={texture}
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        {...dimensions}
                        tint={0xdddddd}
                    />
                    : <></>
            }
            {
                texture
                    && <TilingSprite
                        texture={
                            editingTitle
                                ? texture // Checkmark icon
                                : texture // Pencil icon
                        }
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        width={40}
                        height={40}
                        tint={0xcccccc}
                        position={{
                            x: 40,
                            y: 20,
                        }}
                        interactive
                        cursor='pointer'
                        pointertap={() => {
                            if(editingTitle) {
                                if(book)
                                    setBook({
                                        ...book,
                                        title: props.textInputValue,
                                    });
                                props.setTextInputParams(require('../TextInput/defaultState'));
                                setEditingTitle(false);
                            } else {
                                props.setTextInputParams({
                                    height: 50,
                                    width: 250,
                                    visible: true,
                                    x: globalPosition ? globalPosition?.x + (dimensions.width / 2) : 0,
                                    y: globalPosition ? globalPosition?.y + (dimensions.height / 2) : 0,
                                    value: book?.title,
                                });
                                setEditingTitle(true);
                            }
                        }}
                    />
            }
            {
                (
                    texture && editingTitle
                )
                    && <TilingSprite
                        texture={
                            editingTitle
                                ? texture // Checkmark icon
                                : texture // Pencil icon
                        }
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        width={40}
                        height={40}
                        tint={0xffcccc}
                        position={{
                            x: 90,
                            y: 20,
                        }}
                        interactive
                        cursor='pointer'
                        pointertap={() => {
                            props.setTextInputParams(require('../TextInput/defaultState'));
                            setEditingTitle(false);
                        }}
                    />
            }
            {
                (
                    !!book
                )
                    && <Text
                        text={book.author}
                        style={new PIXI.TextStyle({
                            align: "center",
                            breakWords: true,
                            // fontSize: 20,
                            fontSize: (
                                30 // Base font size
                                /
                                Math.sqrt( // Allow text block to get taller
                                    Math.floor(
                                        book.author.length
                                        /
                                        10 // Base line length
                                    )
                                    + 1
                                )
                            ),
                            wordWrap: true,
                            wordWrapWidth: 350,
                        })}
                        x={
                            dimensions.width / 2
                        }
                        y={
                            (dimensions.height / 2) + 180
                        }
                        anchor={{
                            x: 0.5,
                            y: 0.5,
                        }}
                    />
            }
            {
                (
                    !editingTitle
                    && !!book
                )
                    && <Text
                        text={book.title}
                        style={new PIXI.TextStyle({
                            align: "center",
                            breakWords: true,
                            fontSize: (
                                50 // Base font size
                                /
                                Math.sqrt( // Allow text block to get taller
                                Math.floor(
                                        book.title.length
                                        /
                                        50 // Base line length
                                    )
                                    + 1
                                )
                            ),
                            wordWrap: true,
                            wordWrapWidth: 350,
                        })}
                        x={
                            dimensions.width / 2
                        }
                        y={
                            dimensions.height / 2
                        }
                        anchor={{
                            x: 0.5,
                            y: 0.5,
                        }}
                    />
            }
            <BookPreviewButtons
                store={props?.store}
                ref={bookPreviewButtonsRef}
                x={
                    bookPreviewButtonsBounds?.width
                        ? (
                            ((dimensions.width - bookPreviewButtonsBounds?.width) / 2)
                        )
                        : 0
                }
                y={
                    bookPreviewButtonsBounds?.height
                        ? (
                            ((dimensions.height - bookPreviewButtonsBounds?.height) / 2)
                            + 215
                        )
                        : 0
                }
            />
        </Container>
    );
}

const BookPreviewButtons = forwardRef(
    (props: BookPreviewButtonsPropsType, ref: Ref<PIXI.Container>) => {
        const [editTextRef, setEditTextRef] = useState<PIXI.Text | null | undefined>();
        const [editTextBounds, setEditTextBounds] = useState<PIXI.Rectangle | null | undefined>();
        const [printTextRef, setPrintTextRef] = useState<PIXI.Text | null | undefined>();
        const [printTextBounds, setPrintTextBounds] = useState<PIXI.Rectangle | null | undefined>();
        const [sendTextRef, setSendTextRef] = useState<PIXI.Text | null | undefined>();
        const [sendTextBounds, setSendTextBounds] = useState<PIXI.Rectangle | null | undefined>();
        const [readTextRef, setReadTextRef] = useState<PIXI.Text | null | undefined>();
        const [readTextBounds, setReadTextBounds] = useState<PIXI.Rectangle | null | undefined>();
        const [deleteTextRef, setDeleteTextRef] = useState<PIXI.Text | null | undefined>();
        const [deleteTextBounds, setDeleteTextBounds] = useState<PIXI.Rectangle | null | undefined>();

        useEffect(() => {
            if (!editTextRef) return;
            setEditTextBounds(editTextRef.getBounds());
        }, [editTextRef]);
        useEffect(() => {
            if (!printTextRef) return;
            setPrintTextBounds(printTextRef.getBounds());
        }, [printTextRef]);
        useEffect(() => {
            if (!sendTextRef) return;
            setSendTextBounds(sendTextRef.getBounds());
        }, [sendTextRef]);
        useEffect(() => {
            if (!readTextRef) return;
            setReadTextBounds(readTextRef.getBounds());
        }, [readTextRef]);
        useEffect(() => {
            if (!deleteTextRef) return;
            setDeleteTextBounds(deleteTextRef.getBounds());
        }, [deleteTextRef]);

        return (
            <Container
                ref={ref}
                x={props.x}
                y={props.y}
            >
                <Text
                    ref={ref => setEditTextRef(ref)}
                    text="Edit"
                    style={new PIXI.TextStyle({
                        align: "center",
                        breakWords: true,
                        fontSize: 20,
                        wordWrap: true,
                        wordWrapWidth: 350,
                    })}
                    x={0}
                    y={0}
                />
                <Text
                    ref={ref => setPrintTextRef(ref)}
                    text="Print"
                    style={new PIXI.TextStyle({
                        align: "center",
                        breakWords: true,
                        fontSize: 20,
                        wordWrap: true,
                        wordWrapWidth: 350,
                    })}
                    {
                    ...(
                        !!editTextBounds
                            ? {
                                x: editTextBounds?.width + 10,
                            }
                            : {}
                    )
                    }
                />
                <Text
                    ref={ref => setSendTextRef(ref)}
                    text="Send"
                    style={new PIXI.TextStyle({
                        align: "center",
                        breakWords: true,
                        fontSize: 20,
                        wordWrap: true,
                        wordWrapWidth: 350,
                    })}
                    {
                    ...(
                        (
                            !!editTextBounds
                            && !!printTextBounds
                        )
                            ? {
                                x:
                                    editTextBounds?.width
                                    + 10
                                    + printTextBounds?.width
                                    + 10,
                            }
                            : {}
                    )
                    }
                />
                <Text
                    ref={ref => setReadTextRef(ref)}
                    text="Read"
                    style={new PIXI.TextStyle({
                        align: "center",
                        breakWords: true,
                        fontSize: 20,
                        wordWrap: true,
                        wordWrapWidth: 350,
                    })}
                    {
                    ...(
                        (
                            !!editTextBounds
                            && !!printTextBounds
                            && !!sendTextBounds
                        )
                            ? {
                                x:
                                    editTextBounds?.width
                                    + 10
                                    + printTextBounds?.width
                                    + 10
                                    + sendTextBounds?.width
                                    + 10,
                            }
                            : {}
                    )
                    }
                />
                <Text
                    ref={ref => setDeleteTextRef(ref)}
                    text="Delete"
                    style={new PIXI.TextStyle({
                        align: "center",
                        breakWords: true,
                        fontSize: 20,
                        wordWrap: true,
                        wordWrapWidth: 350,
                    })}
                    {
                    ...(
                        (
                            !!editTextBounds
                            && !!printTextBounds
                            && !!sendTextBounds
                            && !!readTextBounds
                        )
                            ? {
                                x:
                                    editTextBounds?.width
                                    + 10
                                    + printTextBounds?.width
                                    + 10
                                    + sendTextBounds?.width
                                    + 10
                                    + readTextBounds?.width
                                    + 10,
                            }
                            : {}
                    )
                    }
                />
            </Container>
        )
    },
);

export default BookPreview;
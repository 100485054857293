import defaultState from "./defaultState";
import { BookReduxDispatchActionType, BookReduxStateType } from "./types";

const reducer: (state: BookReduxStateType & any, action: BookReduxDispatchActionType) => BookReduxStateType
    = (state: BookReduxStateType & any = defaultState, action: BookReduxDispatchActionType) => {
        switch (action.type) {
            default:
                return state;
        }
    };

export default reducer;
import { TextInputReduxStateType } from "./types";

const defaultState: TextInputReduxStateType = {
    width: 0,
    height: 0,
    anchor: 0,
    x: 0,
    y: 0,
    visible: false,
    value: '',
};

export default defaultState;
import { useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import { Texture, ObservablePoint } from 'pixi.js';
import { Container, Text, TilingSprite } from '@pixi/react';
import { BookPropsType, BookReduxDispatchResultType, BookReduxStateType } from './types';
import { BookType } from '../../types';
import apiController from '../../apiController';

const Book = (props: (BookPropsType & BookReduxDispatchResultType & BookReduxStateType)) => {
    const [bookTexture, setBookTexture] = useState<Texture>();
    const [book, setBook] = useState<BookType|null>({
        author: '',
        _id: '0',
        title: '',
    });

    useEffect(() => {
        (async () => {
            setBookTexture(PIXI.Texture.WHITE);
        })();
    }, [setBookTexture]);
    useEffect(() => {
        if (props.book?._id !== 'new')
            setBook(props.book);

        else setBook({
            author: '',
            title: '+ Add Story',
    _id: '0',
        });
    }, [props.book, setBook]);

    return (
        <Container
            {...props}
            interactive
            cursor='pointer'
            pointertap={
                props.book?._id === 'new'
                    ? () => {
                        apiController.addBook({
                            author: 'User', // TODO: Replace with current user
                            title: 'Test',
                        });
                        props.createNewBook();
                        props.setSelectedBook({
                            author: 'User', // TODO: Replace with current user
                            _id: 'new',
                            title: 'New Story',
                        });
                    }
                    :  props.selected 
                        ? () => props.setSelectedBook(null)
                        : () => props.setSelectedBook(book)
            }
        >
            {
                bookTexture
                    ? <TilingSprite
                        texture={bookTexture}
                        tilePosition={new ObservablePoint(() => { }, null, 16, 16)}
                        width={props.width}
                        height={props.height}
                    />
                    : <></>

            }
            {
                !!book
                    && <Text
                        text={book?.title}
                        style={new PIXI.TextStyle({
                            align: 'center',
                            fontSize: (
                                20 // Base font size
                                /
                                Math.sqrt( // Allow text block to get taller
                                    Math.floor(
                                        book.title.length
                                        /
                                        20 // Base line length
                                    )
                                    + 1
                                )
                            ),
                            wordWrap: true,
                            breakWords: true,
                            wordWrapWidth: props.width,
                        })}
                        anchor={{
                            x: 0.5,
                            y: 0.5,
                        }}
                        position={{
                            x: props.width / 2,
                            y: props.height / 2,
                        }}
                    />
            }
        </Container>
    );
};

export default Book;
import { connect } from 'react-redux';
import Component from './component';
import { AppReduxDispatchActionType, AppReduxDispatchResultType, AppReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';

const mapDispatchToProps: (dispatch: Dispatch<AppReduxDispatchActionType>) => AppReduxDispatchResultType
  = (dispatch: Dispatch<AppReduxDispatchActionType>) => {
    return {
    }
  };

const mapStateToProps: (state: any) => AppReduxStateType = (state: any) => {
  return state[0];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component); 
import defaultState from "./defaultState";
import { AppReduxDispatchActionType, AppReduxStateType } from "./types";

const reducer: (state: AppReduxStateType & any, action: AppReduxDispatchActionType) => AppReduxStateType
    = (state: AppReduxStateType & any = defaultState, action: AppReduxDispatchActionType) => { // removing "& any" from all reducer files breaks configure-store.ts
        switch (action.type) {
            case "CREATE_NEW_STORY":
                return {
                    ...state,
                    screen: "NEW_BOOK",
                };
            case 'SET_SELECTED_BOOK':
                return {
                    ...state,
                    selectedBook: action.value,
                    showTitleCreationScreen: (
                        action.value?._id === 'new'
                    ),
                };
            case 'CANCEL_TITLE_CREATION':
                return {
                    ...state,
                    showTitleCreationScreen: null,
                    selectedBook: null,
                    screen: "BOOK_SELECTION",
                }
            default:
                return state;
        }
    };

export default reducer;
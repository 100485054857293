import { BookSelectionScreenReduxDispatchResultType, BookSelectionScreenReduxStateType, BookSelectionScreenPropsType } from './types';
import * as PIXI from 'pixi.js';
import { Container, Text } from '@pixi/react';
import { Container as BooksContainer } from '../BooksContainer/container';


const BookSelectionScreen = (props: (BookSelectionScreenPropsType & BookSelectionScreenReduxDispatchResultType & BookSelectionScreenReduxStateType)) => {

    return (
        <Container
            position={[380, 240]}
        >
            <Text
                text="STORY MAKER"
                anchor={0}
                x={-360}
                y={-220}
                style={
                    new PIXI.TextStyle({
                        fontFamily: '"Squada One"',
                        fontSize: 45,
                        fill: ['#ffffff'], // gradient
                        stroke: '#000000',
                        strokeThickness: 3,
                        dropShadow: true,
                        dropShadowColor: '#5555ff',
                        dropShadowBlur: 5,
                        dropShadowAngle: 0,
                        dropShadowDistance: 0,
                    })
                }
            />
            <Text
                text={
                    props.selectedBook
                        ? "Use the buttons at the bottom of the book.  Send will email your book to your guardian."
                        : "Select a Story or click ADD STORY to start writing a new Story."
                }
                anchor={0}
                x={-360}
                y={-160}
                style={
                    new PIXI.TextStyle({
                        // align: 'left',
                        fontFamily: '"Squada One"',
                        fontSize: 20,
                        // fontWeight: '900',
                        fill: ['#ffffff'], // gradient
                        stroke: '#000000',
                        strokeThickness: 3,
                        letterSpacing: 0,
                        dropShadow: true,
                        dropShadowColor: '#5555ff',
                        dropShadowBlur: 5,
                        // dropShadowAngle: Math.PI / 6,
                        dropShadowAngle: 0,
                        dropShadowDistance: 0,
                        wordWrap: true,
                        wordWrapWidth: 340,
                    })
                }
            />
            <BooksContainer
                x={-360}
                y={-60}
                store={props.store}
            />
        </Container>
    );
};

export default BookSelectionScreen;
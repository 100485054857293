import { connect } from 'react-redux';
import Component from './component';
import { BookPreviewReduxDispatchActionType, BookPreviewReduxDispatchResultType, BookPreviewReduxStateType } from './types';
import { Dispatch } from '@reduxjs/toolkit';
import { TextInputReduxStateType } from '../TextInput/types';

const mapDispatchToProps: (dispatch: Dispatch<BookPreviewReduxDispatchActionType>) => BookPreviewReduxDispatchResultType
  = (dispatch: Dispatch<BookPreviewReduxDispatchActionType>) => {
    return {
      setTextInputParams: (params: TextInputReduxStateType) => { dispatch({ type: 'SET_TEXT_INPUT_PARAMS', value: params}); },
    }
  };

const mapStateToProps: (state: any) => BookPreviewReduxStateType = (state: any) => {
  return state[3];
};

export const Container = connect(mapStateToProps, mapDispatchToProps)(Component);
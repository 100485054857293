import { Stage } from '@pixi/react';
import { AppPropsType, AppReduxDispatchResultType, AppReduxStateType } from './types';
import { Container as BookPreview } from '../BookPreview/container';
import { Container as BookSelectionScreen } from '../BookSelectionScreen/container';
import { Container as StageBackground } from '../StageBackground/container';
import { Container as TextInput } from '../TextInput/container';
import { useState } from 'react';

function App(props: AppPropsType & AppReduxDispatchResultType & AppReduxStateType) {
  const [dimensions] = useState({
    width: 760,
    height: 480,
  });

  return (
    <div>
      <Stage
        width={dimensions.width}
        height={dimensions.height}
        options={{
          backgroundColor: 0x1e2978,
        }}
      >
        <StageBackground store={props.store} />
        {
          (() => {
            switch (props.screen) {
              case "NEW_BOOK":
              case "BOOK_SELECTION":
              default:
                return (
                  <BookSelectionScreen store={props.store}></BookSelectionScreen>
                );
            }
          })()
        }
        {
          (
            !!props.selectedBook
          )
          && <BookPreview
            x={380}
            y={10}
            store={props.store}
          />
        }
      </Stage>
      <TextInput
        // height={24}
        // width={200}
        // visible
        // anchor={0.5}
        // x={480}
        // y={240}
        // style={{
        //   backgroundColor: '#fff1',
        //   borderRadius: 10,
        //   border: '1px solid black',
        //   textAlign: 'center',
        //   verticalAlign: 'middle',
        // }}
        // elementType='input'
      />
    </div>
  );
};

export default App;
